import { isBrowser } from '@core/helpers'

export const isIntersectionObserverAvailable = () =>
  isBrowser() && 'IntersectionObserver' in window

/**
 *
 * @param {VueRef} $el Element observed
 * @returns IntersectionObserver
 */
export const createObserver = ($el, callback) => {
  if (!isIntersectionObserverAvailable()) {
    return () => {}
  }

  const observer = new IntersectionObserver((entries) => {
    // Use `intersectionRatio` because of Edge 15's
    // lack of support for `isIntersecting`.
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (entries[0].intersectionRatio <= 0) return

    observer.unobserve($el)

    callback()
  })

  observer.observe($el)

  // Expose the method to unobserve the element
  // Useful to remove the observability on destroy
  const unobserve = () => {
    observer.disconnect()
  }

  return unobserve
}
