export default {
  prevSlideText: {
    id: 'cms_product_recommendations_slider_prev_slide',
    defaultMessage: 'Display previous slide',
  },
  nextSlideText: {
    id: 'cms_product_recommendations_slider_next_slide',
    defaultMessage: 'Display next slide',
  },
}
