
import { RevButton } from '@ds/components/Button'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import RecommendedProductsBlock from '@recommendation/components/RecommendedProductsBlock/RecommendedProductsBlock.vue'

import { sourceTypeForProductsTracking } from '../../helpers/tracking'
import BlockTitle from '../private/BlockTitle'

import translations from './Recommendations.translations'

const NUMBER_MAX_OFFERS_TO_REQUEST = 16

export default {
  components: {
    RevButton,
    BlockTitle,
    RecommendedProductsBlock,
  },

  props: {
    blockPosition: {
      type: Number,
      required: true,
    },
    widgetId: {
      type: String,
      required: true,
    },
    numberOffersMax: {
      type: Number,
      default: NUMBER_MAX_OFFERS_TO_REQUEST,
    },
    ctaLink: {
      type: Object,
      default: () => ({}),
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showRecoTitle: false,
      showReco: true,
    }
  },

  computed: {
    ...mapGetters({
      showCarrier: 'config/showCarrier',
      userId: 'auth/id',
      isAuthAvailable: 'auth/fetched',
    }),

    translations: () => translations,

    hasCtaLink() {
      return !isEmpty(this.ctaLink)
    },

    sourceType() {
      const { name, params } = this.$route

      return sourceTypeForProductsTracking({
        blockPosition: this.blockPosition,
        pageName: params.pageName,
        routeName: name,
      })
    },
  },

  methods: {
    async handleRecoLoaded(results) {
      if (!isEmpty(results)) {
        this.showRecoTitle = true

        return
      }

      this.$emit('error')
      this.showReco = false
    },
  },
}
